import { useEffect, useRef } from 'react';

/// <summary>
/// Author: -
/// </summary>
const useIdleTimer = (timeout, onIdle) => {
    const idleTimeoutRef = useRef(null);
    const lastActivityRef = useRef(Date.now());

    /// <summary>
    /// Author: -
    /// </summary>
    useEffect(() => {
        resetTimer();
        window.addEventListener('mousemove', handleUserActivity);
        window.addEventListener('keydown', handleUserActivity);

        return () => {
            if (idleTimeoutRef.current) clearTimeout(idleTimeoutRef.current);
            window.removeEventListener('mousemove', handleUserActivity);
            window.removeEventListener('keydown', handleUserActivity);
        };
    }, [timeout, onIdle]);

    /// <summary>
    /// Author: -
    /// </summary>
    const resetTimer = () => {
        lastActivityRef.current = Date.now();
        if (idleTimeoutRef.current) clearTimeout(idleTimeoutRef.current);
        idleTimeoutRef.current = setTimeout(() => {
            const timeSinceLastActivity = Date.now() - lastActivityRef.current;
            if (timeSinceLastActivity >= timeout) {
                onIdle();
            }
        }, timeout);
    };

    /// <summary>
    /// Author: -
    /// </summary>
    const handleUserActivity = () => {
        resetTimer();
    };

    return resetTimer;
};

export default useIdleTimer;