import React, { useState, useEffect } from "react";
import {
    ProSidebar,
    Menu,
    MenuItem,
    SubMenu,
    SidebarHeader,
    SidebarFooter,
    SidebarContent,
} from "react-pro-sidebar";
import { NavLink, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { stringIsNullOrEmpty, showMessage, navigateTo, isObjectEmpty, createFormBody } from '../../util/Util'
import { AccessRight, WebUrl, ApiKey, ApiUrl, AlertTypes, ValidationPattern, Role } from "../../util/Constant";
import ApiEngine from '../../util/ApiEngine.js';
import { useTranslation } from 'react-i18next';
import Menus from '../top-menu/menu.jsx';
import { performLogout } from '../../redux/AuthAction';
import CreditLimit from '../custom/CreditLimit';
import DropdownLanguage from './dropdown/language.jsx';
import { Modal, ModalHeader, ModalBody, ModalFooter, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Col, Row } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import Input from '../../components/custom/Input';
import { Link } from 'react-router-dom';
import { Scrollbar } from "react-scrollbars-custom";
import { checkIfPermissionExist } from "../../util/PermissionChecker";
import useIdleTimer from "../../util/useIdleTimer.js";
import "react-pro-sidebar/dist/css/styles.css";
import "./sidebar.css";


const Sidebar = () => {
    var _dispatch = useDispatch();
    var _history = useHistory();
    const { t } = useTranslation();
    const { register, handleSubmit, watch, setValue, control, errors } = useForm();
    const { handleSubmit: handleSubmitSecondaryPassword, control: controlSecondaryPassword, errors: errorsSecondaryPassword } = useForm();
    const _userData = useSelector(state => state.authState.userData);
    const _isLoggedIn = useSelector(state => state.authState.isLoggedIn);
    const [filteredMenu, setFilteredMenu] = useState([]);
    const [filteredProfileMenu, setFilteredProfileMenu] = useState([]);
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const [showProfileMenu, setShowProfileMenu] = useState(false);
    const [passwordModal, setPasswordModal] = useState({});
    const ProfileMenu = [
        { path: WebUrl._URL_MANAGE_PROFILE, title: 'MANAGE_PROFILE', mainAccountOnly: true },
        {
            title: 'EDIT_FIGHT_LIMIT', onClick: () => { navigateTo(_history, `${WebUrl._URL_FIGHT_LIMIT}?user=${_userData.userId}&name=${_userData.username}`); },
            onClick: () => {
                var width = 1000;
                var height = 800;
                var left = (window.screen.width / 2) - (width / 2);
                var top = (window.screen.height / 2) - (height / 2);

                window.open(`${WebUrl._URL_FIGHT_LIMIT}?user=${_userData.userId}&name=${_userData.username}`, `Fight Limit`, `directories=no,location=no,width=${width},height=${height},left=${left},top=${top}`);
            },
            mainAccountOnly: true, role: [Role._HOUSE, Role._MULTILEVEL, Role._AGENT, Role._SUPER_SENIOR, Role._SENIOR, Role._MASTER, Role._COMPANY, Role._SHAREHOLDER, Role._ADMIN],
            permission: { activity: AccessRight._FIGHT_LIMIT_PERMISSION, accessLevel: [AccessRight._VIEW_PERMISSION] }
        },
        {
            path: WebUrl._URL_PACKAGE_DETAIL, title: 'RED_PACKAGE_SETTINGS',
            mainAccountOnly: true, role: [Role._HOUSE, Role._MULTILEVEL, Role._AGENT, Role._SUPER_SENIOR, Role._SENIOR, Role._MASTER, Role._COMPANY, Role._SHAREHOLDER, Role._ADMIN],
            state: { isRedPackage: true }
        },
        { title: 'CHANGE_PASSWORD', onClick: () => { setShowChangePasswordModal(true); } },
    ]

    /// <summary>
    /// Author: -
    /// </summary>
    const handleIdle = () => {
        if (_isLoggedIn) {
            _dispatch(performLogout());
        }
    };

    /// <summary>
    /// Author: -
    /// not detect mouse move or key down event for 30 mins will logout user
    /// </summary>
    useIdleTimer(30 * 60 * 1000, handleIdle);

    /// <summary>
    /// Author: -
    /// </summary>
    useEffect(() => {
        if (!stringIsNullOrEmpty(_userData.accessString)) {
            filterMenuByPermission();
            filterProfileMenuByPermission();
        }
    }, [_userData])

    /// <summary>
    /// Author: -
    /// </summary>
    const filterMenuByPermission = () => {
        var menuToUse = JSON.parse(JSON.stringify(Menus));
        var userPermissionData = _userData.accessString.split(";");
        var permissionArrKey = [];

        for (var i = 0; i < userPermissionData.length; i++) {
            var keyValuePermission = userPermissionData[i].split(":");

            if (keyValuePermission.length == 2) {
                var accessLevel = keyValuePermission[1].toString();

                if (accessLevel.includes(AccessRight._VIEW_PERMISSION)) {
                    permissionArrKey.push({ activity: keyValuePermission[0], accessLevel: accessLevel.split("") });
                }
            }
        }

        //first filter away non use children
        for (var i = 0; i < menuToUse.length; i++) {
            if (menuToUse[i].children) {
                menuToUse[i].children = menuToUse[i].children.filter(item => {
                    let permission = item['permission'];
                    let role = item['role'];
                    let hasPermission = true;

                    if (permission !== undefined) {
                        hasPermission = permissionArrKey.some(i => i.activity == permission.activity && i.accessLevel.some(i => permission.accessLevel.includes(i)));
                    }

                    if (hasPermission && role !== undefined) {
                        hasPermission = role.some(i => i == _userData.userRoleId);
                    }

                    return hasPermission;
                });
            }
        }

        //secondly filter away parent
        menuToUse = menuToUse.filter(item => {
            if ((!item.children || item.children.length == 0) && item.isDummy) {
                return false;
            }

            let permission = item.permission;
            let role = item.role;

            let hasPermission = true;

            if (permission !== undefined) {
                hasPermission = permissionArrKey.some(i => i.activity == permission.activity && i.accessLevel.some(i => permission.accessLevel.includes(i)));
            }

            if (hasPermission && role !== undefined) {
                hasPermission = role.some(i => i == _userData.userRoleId);
            }

            return hasPermission;
        });

        setFilteredMenu(menuToUse);
    }

    /// <summary>
    /// Author: -
    /// </summary>
    const filterProfileMenuByPermission = () => {
        var menuToUse = ProfileMenu;
        var userPermissionData = _userData.accessString.split(";");
        var permissionArrKey = [];

        for (var i = 0; i < userPermissionData.length; i++) {
            var keyValuePermission = userPermissionData[i].split(":");

            if (keyValuePermission.length == 2) {
                var accessLevel = keyValuePermission[1].toString();

                if (accessLevel.includes(AccessRight._VIEW_PERMISSION)) {
                    permissionArrKey.push({ activity: keyValuePermission[0], accessLevel: accessLevel.split("") });
                }
            }
        }

        menuToUse = menuToUse.filter(item => {
            if ((!item.children || item.children.length == 0) && item.isDummy) {
                return false;
            }

            let permission = item.permission;
            let role = item.role;
            let mainAccountOnly = item.mainAccountOnly;
            let hasPermission = true;

            if (permission !== undefined) {
                hasPermission = permissionArrKey.some(i => i.activity == permission.activity && i.accessLevel.some(i => permission.accessLevel.includes(i)));
            }

            if (hasPermission && role !== undefined) {
                hasPermission = role.some(i => i == _userData.userRoleId);
            }

            if (hasPermission && mainAccountOnly !== undefined && mainAccountOnly) {
                hasPermission = stringIsNullOrEmpty(_userData.mainAccount);
            }

            return hasPermission;
        });

        setFilteredProfileMenu(menuToUse);
    }

    /// <summary>
    /// Author: -
    /// </summary>
    function logoutHandler() {
        showMessage({
            type: AlertTypes._WARNING,
            content: t("PROCEED_LOGOUT_ALERT"),
            showCancel: true,
            confirmBtnText: t("LOGOUT"),
            onConfirm: () => {
                _dispatch(performLogout());
            }
        });
    }

    /// <summary>
    /// Author: -
    /// </summary>
    const onChangePassword = async (data, e) => {
        if (data.password != data.confirmPassword) {
            showMessage({ type: AlertTypes._ERROR, content: t("CONFIRM_PASSWORD_AND_PASSWORD_IS_NOT_TALLY") });
            return;
        }

        var responseJson = await ApiEngine.post(`${ApiUrl._API_CHANGE_PASSWORD}?newPassword=${data.password}&oldPassword=${data.oldPassword}`);

        showMessage({
            type: responseJson[ApiKey._API_SUCCESS_KEY] ? AlertTypes._SUCCESS : AlertTypes._ERROR,
            content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
            onConfirm: () => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setShowChangePasswordModal(false);
                }
            }
        });
    }

    /// <summary>
    /// Author: -
    /// </summary>
    const checkSecondaryPassword = async (data, e) => {
        var responseJson = await ApiEngine.post(`${ApiUrl._API_VERIFY_SECONDARY_PASSWORD}?secondaryPassword=${data.secondaryPassword}`);

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            navigateTo(_history, passwordModal.path);
            setPasswordModal({});
        }
        else {
            showMessage({
                type: AlertTypes._ERROR,
                content: t(responseJson[ApiKey._API_MESSAGE_KEY])
            });
        }
    }

    return (
        <>
            <div id="header">
                <ProSidebar >
                    <SidebarHeader>
                        <Row>
                        </Row>
                        <Row style={{ paddingBlock: '5px', justifyContent: 'space-between' }}>
                            <DropdownLanguage />
                            {
                                checkIfPermissionExist(AccessRight._FIGHT_LIMIT_PERMISSION, AccessRight._VIEW_PERMISSION) &&
                                ProfileMenu[1].role.some(i => i == _userData.userRoleId) &&
                                <button className="btn btn-primary-outline" onClick={() => {
                                    var width = 1000;
                                    var height = 800;
                                    var left = (window.screen.width / 2) - (width / 2);
                                    var top = (window.screen.height / 2) - (height / 2);

                                    window.open(`${WebUrl._URL_FIGHT_LIMIT}?user=${stringIsNullOrEmpty(_userData.mainAccount) ? _userData.userId : _userData.mainAccount}&name=${stringIsNullOrEmpty(_userData.mainAccount) ? _userData.username : 'MAIN-ACCOUNT'}`, `Fight Limit`, `directories=no,location=no,width=${width},height=${height},left=${left},top=${top}`);
                                }}>
                                    FL
                                </button>
                            }
                            {
                                checkIfPermissionExist(AccessRight._RED_NUMBER_PERMISSION, AccessRight._VIEW_PERMISSION) &&
                                ProfileMenu[1].role.some(i => i == _userData.userRoleId) &&
                                <button className="btn btn-primary-outline" onClick={() => {
                                    window.open(`${WebUrl._URL_RED_NUMBER}?userId=${_userData.userId}&username=${_userData.username}`, "_blank");
                                }}>
                                    RN
                                </button>
                            }
                            {
                                _userData.resendOrderLimitEnable &&
                                <button className="btn btn-primary-outline" onClick={() => {
                                    window.open(`${WebUrl._URL_RESEND_ORDER_LIMIT}?user=${stringIsNullOrEmpty(_userData.mainAccount) ? _userData.userId : _userData.mainAccount}&name=${stringIsNullOrEmpty(_userData.mainAccount) ? _userData.username : 'MAIN-ACCOUNT'}`, "_blank");
                                }}>
                                    RL
                                </button>
                            }
                        </Row>
                        <Row>
                            <button className="btn btn-logout clickableLink" onClick={() => logoutHandler()}>
                                <img src={require(`../../assets/img/svg/${process.env.REACT_APP_ASSET_PATH}logout.svg`)} />
                            </button>
                        </Row>
                        <Row>
                            <Col xl={12}>
                                <Link className='clickableLink' style={{ fontSize: '18px', fontWeight: 700 }}
                                    to={{
                                        pathname: WebUrl._URL_MANAGE_PROFILE,
                                    }}>
                                    {_userData?.username}
                                </Link>
                            </Col>
                        </Row>
                        <CreditLimit />
                    </SidebarHeader>
                    <SidebarContent>
                        <Scrollbar>
                            <Menu>
                                {
                                    filteredMenu.map((menu, parentIndex) => {
                                        const index = parentIndex + 1;

                                        return (
                                            _userData.userId != menu.temporaryDisableFor &&
                                            (
                                                menu.isDummy ?
                                                    <SubMenu key={parentIndex} title={index + '. ' + t(menu.title)} style={{ paddingLeft: '8px' }}>
                                                        {
                                                            menu.children.map((childMenu, childIndex) => {
                                                                return (
                                                                    <MenuItem key={childIndex}>
                                                                        <NavLink
                                                                            to={{
                                                                                pathname: childMenu.path,
                                                                                state: childMenu.state
                                                                            }}>
                                                                            <span>
                                                                                <span>{`${index}.${childIndex + 1} `}</span>
                                                                                {t(childMenu.title)}
                                                                            </span>
                                                                        </NavLink>
                                                                    </MenuItem>
                                                                )
                                                            })
                                                        }
                                                    </SubMenu> :
                                                    <MenuItem key={parentIndex}>
                                                        <NavLink to={{
                                                            pathname: menu.path,
                                                            state: menu.state
                                                        }}>
                                                            <span>{index}. </span>
                                                            {t(menu.title)}
                                                        </NavLink>
                                                    </MenuItem>
                                            )
                                        )
                                    })
                                }
                            </Menu>
                        </Scrollbar>
                    </SidebarContent>
                    <SidebarFooter>
                        <label>Copyright © {new Date().getFullYear()}</label>
                    </SidebarFooter>
                </ProSidebar>
                <Modal isOpen={showChangePasswordModal}>
                    <form onSubmit={handleSubmit(onChangePassword)} className="passwordModal">
                        <ModalBody>
                            <h4 style={{ textAlign: 'center' }}>
                                {t('CHANGE_PASSWORD')}
                                <br />
                                <img src={require(`../../assets/img/png/${process.env.REACT_APP_ASSET_PATH}change-pw-graphic.png`)} width={'50%'} height={'50%'} />
                            </h4>
                            <div className="form-group row m-b-15">
                                <div className="col-lg-12">
                                    <Controller
                                        control={control}
                                        name="oldPassword"
                                        render={({ onChange, value, name }) => (
                                            <Input
                                                name={name}
                                                value={value}
                                                onChange={value => {
                                                    onChange(value);
                                                }}
                                                label={`${t("OLD_PASSWORD")}*`}
                                                placeholder={`${t("OLD_PASSWORD")}`}
                                                type="password"
                                                errors={errors}
                                            />
                                        )}
                                        rules={{ required: true }}
                                    />
                                </div>
                            </div>
                            <div className="form-group row m-b-15">
                                <div className="col-md-12">
                                    <Controller
                                        control={control}
                                        name="password"
                                        render={({ onChange, value, name }) => (
                                            <Input
                                                name={name}
                                                value={value}
                                                onChange={value => {
                                                    onChange(value);
                                                }}
                                                label={`${t("PASSWORD")}*`}
                                                placeholder={`${t("PASSWORD")}`}
                                                type="password"
                                                errors={errors}
                                            />
                                        )}
                                        rules={{ required: true }}
                                    />
                                </div>
                            </div>
                            <div className="form-group row m-b-15">
                                <div className="col-lg-12">
                                    <Controller
                                        control={control}
                                        name="confirmPassword"
                                        render={({ onChange, value, name }) => (
                                            <Input
                                                name={name}
                                                value={value}
                                                onChange={value => {
                                                    onChange(value);
                                                }}
                                                label={`${t("CONFIRM_PASSWORD")}*`}
                                                placeholder={`${t("CONFIRM_PASSWORD")}`}
                                                type="password"
                                                errors={errors}
                                            />
                                        )}
                                        rules={{ required: true }}
                                    />
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <button type="submit" className="btn btn-primary">{t('BTN_SAVE')}</button>
                            <button type="button" className="btn btn-danger" onClick={() => { setShowChangePasswordModal(false); }}>{t('BTN_CANCEL')}</button>
                        </ModalFooter>
                    </form>
                </Modal>
                <Modal isOpen={!isObjectEmpty(passwordModal)}>
                    <form onSubmit={handleSubmitSecondaryPassword(checkSecondaryPassword)} className="passwordModal">
                        <ModalBody>
                            <h4 style={{ textAlign: 'center' }}>
                                {t('REQUIRED_PASSWORD_TO_PROCEED') + ' (' + t(passwordModal?.title) + ')'}
                            </h4>
                            <div className="form-group row m-b-15">
                                <div className="col-lg-12">
                                    <Controller
                                        control={controlSecondaryPassword}
                                        name="secondaryPassword"
                                        render={({ onChange, value, name }) => (
                                            <Input
                                                name={name}
                                                value={value}
                                                onChange={value => {
                                                    onChange(value);
                                                }}
                                                label={`${t("PASSWORD")}*`}
                                                placeholder={`${t("SECONDARY_PASSWORD")}`}
                                                type="password"
                                                errors={errorsSecondaryPassword}
                                            />
                                        )}
                                        rules={{ required: true }}
                                    />
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <button type="submit" className="btn btn-primary">{t('BTN_SUBMIT')}</button>
                            <button type="button" className="btn btn-danger" onClick={() => { setPasswordModal({}); }}>{t('BTN_CANCEL')}</button>
                        </ModalFooter>
                    </form>
                </Modal>
            </div>
        </>
    );
};

export default Sidebar;