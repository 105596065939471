import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ApiKey, ApiUrl, Pool, InputValidationKey, AlertTypes, Status, SettingKey, EatType, BetType, Role } from "../../util/Constant";
import { useTranslation } from 'react-i18next';
import { showMessage, numberWithCurrencyFormat, isObjectEmpty } from "../../util/Util";
import ApiEngine from "../../util/ApiEngine";
import ClipLoader from "react-spinners/ClipLoader";
import { Link } from 'react-router-dom';

/// <summary>   
/// Author: -
/// </summary>
const CreditLimit = props => {
    const { t } = useTranslation();
    const _userData = useSelector(state => state.authState.userData);
    const [userInfo, setUserInfo] = useState();
    const [isLoading, setIsLoading] = useState(false);

    /// <summary>
    /// Author: -
    /// </summary>
    useEffect(() => {
        if (!isObjectEmpty(_userData)) {
            getUserInfo(_userData.userId);
        }
    }, []);

    /// <summary>
    /// Author: -
    /// </summary>
    async function getUserInfo(userId) {
        setIsLoading(true);
        var responseJson = await ApiEngine.get(ApiUrl._API_GET_USER_DETAIL_BY_ID + "?&userId=" + userId, { headers: { "skipLoading": true } });

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            setUserInfo(responseJson[ApiKey._API_DATA_KEY]);
        }
        else {
            showMessage({
                type: AlertTypes._ERROR,
                content: t(responseJson[ApiKey._API_MESSAGE_KEY])
            });
        }

        setIsLoading(false);
    }

    return (
        <div>
            <Link className='clickableLink' style={{ fontSize: '15px' }} onClick={() => { getUserInfo(_userData.userId) }}>
                {
                    isLoading ?
                        <div className="m-l-15"><ClipLoader size={20} color={"blue"} /></div> :
                        userInfo && userInfo.agent ?
                            `${numberWithCurrencyFormat(userInfo.agent.creditLeft)} / ${numberWithCurrencyFormat(userInfo.agent.creditLimit)}`
                            : `0.00 / 0.00`
                }
            </Link>
        </div>
    );
}

export default CreditLimit;