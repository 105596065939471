import React, { useEffect, useState, useContext, useMemo } from 'react';
import { useLocation } from "react-router-dom";
import { showMessage, useQuery, numberWithCurrencyFormat, isObjectEmpty, getPrizeNameById } from '../../util/Util';
import { ApiKey, ApiUrl, AlertTypes } from "../../util/Constant";
import { useTranslation } from 'react-i18next';
import ApiEngine from "../../util/ApiEngine";
import { PageSettings } from '../../config/page-settings.js';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import _ from 'lodash';
import ReactTable from '../../components/custom/ReactTable';
import { func } from 'prop-types';

/// <summary>
/// Author: -
/// Edited: - - Changed to react table
/// </summary> 
const MapNewDetail = () => {
    var { t } = useTranslation();
    var _location = useLocation();
    var _queryStr = useQuery(_location);
    var _context = useContext(PageSettings);
    const [winningModalDetail, setWinningModalDetail] = useState([]);
    const [ticketModalData, setTicketModalData] = useState({});
    const [betType, setBetType] = useState({});
 
    // Columns that available for the map details
    const [col, setCol] = useState([]);
    const [reportData, setReportData] = useState([]);

    /// <summary>
    /// Author: -
    /// </summary>
    useEffect(() => {
        if (_queryStr.has('agentName') && _queryStr.has('agentId') && _queryStr.has('betNumber') && _queryStr.has('drawDate')
            && _queryStr.has('poolId') && _queryStr.has('betType') && _queryStr.has('searchType')) {
            _context.handleSetPageHeader(false);
            _context.handleSetPageContentFullWidth(true);
            init();
        }
    }, []);

    /// <summary>
    /// Author: -
    /// </summary>
    async function init() {
        try {
            let params = {
                "agentId": _queryStr.get('agentId'),
                "agentName": _queryStr.get('agentName'),
                "betNumber": _queryStr.get('betNumber'),
                "drawDate": _queryStr.get('drawDate'),
                "poolId": _queryStr.get('poolId'),
                "betType": _queryStr.get('betType'),
                "searchType": _queryStr.get('searchType')
            }

            setBetType(_queryStr.get('betType'));

            var responseJson = await ApiEngine.post(ApiUrl._API_GET_MAP_DETAILS, params);

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                if (_queryStr.get('betType') == 1) {
                    setReportData(_.orderBy(responseJson["data"].d2DetailModels, (data) => data.number, ['asc']));
                    setCol(await constructTableCol2D());
                }
                else if (_queryStr.get('betType') == 2) {
                    setReportData(_.orderBy(responseJson["data"].d3DetailModels, (data) => data.number, ['asc']));
                    setCol(await constructTableCol3D());
                }
                else if (_queryStr.get('betType') == 3) {
                    console.log('hhwhwhw');
                    console.log(responseJson["data"].d4DetailModels);
                    setReportData(_.orderBy(responseJson["data"].d4DetailModels, (data) => data.number, ['asc']));
                    setCol(await constructTableCol4D());
                }
                else if (_queryStr.get('betType') == 4) {
                    setReportData(_.orderBy(responseJson["data"].d5DetailModels, (data) => data.number, ['asc']));
                    setCol(await constructTableCol5D());
                }
                else if (_queryStr.get('betType') == 5) {
                    setReportData(_.orderBy(responseJson["data"].d6DetailModels, (data) => data.number, ['asc']));
                    setCol(await constructTableCol6D());
                }
            }
            else {
                throw responseJson[ApiKey._API_MESSAGE_KEY]
            }
        }
        catch (err) {
            showMessage({
                type: AlertTypes._ERROR,
                content: err
            });
        }
    }

    /// <summary>
    /// Author: -
    /// </summary>
    async function onTicketClick(ticketId) {
        try {
            let responseJson = await ApiEngine.get(ApiUrl._API_GET_BET_RECEIPT + "?ticketId=" + ticketId);

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setTicketModalData(responseJson[ApiKey._API_DATA_KEY] ?? {});
            }
            else {
                throw responseJson[ApiKey._API_MESSAGE_KEY]
            }
        }
        catch (err) {
            showMessage({
                type: AlertTypes._ERROR,
                content: err
            });
        }
    }

    async function constructTableCol4D() {
        let col = [
            {
                Header: 'DATE',
                accessor: 'drawDate',
            },
            {
                Header: 'AGENT',
                accessor: 'orderingAgent',
            },
            {
                Header: 'TIX',
                accessor: 'ticketId',
                Cell: ({ row }) => {
                    return <span className="clickableLink" onClick={() => onTicketClick(row.original.ticketId)}>{row.original.ticketId}</span>;
                },
            },
            {
                Header: 'NUMBER',
                accessor: 'number',
            },
            {
                Header: 'POOL',
                accessor: 'poolName',
            },
            {
                Header: 'PACKAGE',
                accessor: 'packageName',
                Footer: () => {
                    return <span><b>{t('TOTAL')}:</b></span>
                },
            },
            {
                Header: "SIMPLE_BIG",
                disableSortBy: true,
                className: 'column-right',
                customHeader: ({ row }) => {
                    return <span style={{ cursor: "pointer" }}>{t(`SIMPLE_BIG`)}</span>
                },
                Cell: ({ row, value }) => {                    
                    return <div>{numberWithCurrencyFormat(row.original['big'], 2)}</div>
                },
                Footer: ({ row, page }) => {                    
                    const total = page.reduce((sum, currentValue) => {
                        return sum + parseFloat(currentValue.original['big']);
                    }, 0);
                    return <span><b>{numberWithCurrencyFormat(total, 2)}</b></span>
                }
            },
            {
                Header: "SIMPLE_SMALL",
                disableSortBy: true,
                className: 'column-right',
                customHeader: ({ row }) => {
                    return <span style={{ cursor: "pointer" }}>{t(`SIMPLE_SMALL`)}</span>
                },
                Cell: ({ row, value }) => {
                    return <div>{numberWithCurrencyFormat(row.original['small'],2)}</div>
                },
                Footer: ({ row, page }) => {
                    const total = page.reduce((sum, currentValue) => {
                        return sum + parseFloat(currentValue.original['small']);
                    }, 0);
                    return <span><b>{numberWithCurrencyFormat(total, 2)}</b></span>
                }
            },
            {
                Header: "SIMPLE_4A",
                disableSortBy: true,
                className: 'column-right',
                customHeader: ({ row }) => {
                    return <span style={{ cursor: "pointer" }}>{t(`SIMPLE_4A`)}</span>
                },
                Cell: ({ row, value }) => {
                    return <div>{numberWithCurrencyFormat(row.original['d4A'],2)}</div>
                },
                Footer: ({ row, page }) => {
                    const total = page.reduce((sum, currentValue) => {
                        return sum + parseFloat(currentValue.original['d4A']);
                    }, 0);
                    return <span><b>{numberWithCurrencyFormat(total, 2)}</b></span>
                }
            },
            {
                Header: "SIMPLE_4SB",
                disableSortBy: true,
                className: 'column-right',
                customHeader: ({ row }) => {
                    return <span style={{ cursor: "pointer" }}>{t(`SIMPLE_4SB`)}</span>
                },
                Cell: ({ row, value }) => {
                    return <div>{numberWithCurrencyFormat(row.original['d4SB'],2)}</div>
                },
                Footer: ({ row, page }) => {
                    const total = page.reduce((sum, currentValue) => {
                        return sum + parseFloat(currentValue.original['d4SB']);
                    }, 0);
                    return <span><b>{numberWithCurrencyFormat(total, 2)}</b></span>
                }
            },
            {
                Header: "SIMPLE_4SC",
                disableSortBy: true,
                className: 'column-right',
                customHeader: ({ row }) => {
                    return <span style={{ cursor: "pointer" }}>{t(`SIMPLE_4SC`)}</span>
                },
                Cell: ({ row, value }) => {
                    return <div>{numberWithCurrencyFormat(row.original['d4SC'],2)}</div>
                },
                Footer: ({ row, page }) => {
                    const total = page.reduce((sum, currentValue) => {
                        return sum + parseFloat(currentValue.original['d4SC']);
                    }, 0);
                    return <span><b>{numberWithCurrencyFormat(total, 2)}</b></span>
                }
            },
            {
                Header: "SIMPLE_4SD",
                disableSortBy: true,
                className: 'column-right',
                customHeader: ({ row }) => {
                    return <span style={{ cursor: "pointer" }}>{t(`SIMPLE_4SD`)}</span>
                },
                Cell: ({ row, value }) => {
                    return <div>{numberWithCurrencyFormat(row.original['d4SD'],2)}</div>
                },
                Footer: ({ row, page }) => {
                    const total = page.reduce((sum, currentValue) => {
                        return sum + parseFloat(currentValue.original['d4SD']);
                    }, 0);
                    return <span><b>{numberWithCurrencyFormat(total, 2)}</b></span>
                }
            },
            {
                Header: "SIMPLE_4SE",
                disableSortBy: true,
                className: 'column-right',
                customHeader: ({ row }) => {
                    return <span style={{ cursor: "pointer" }}>{t(`SIMPLE_4SE`)}</span>
                },
                Cell: ({ row, value }) => {
                    return <div>{numberWithCurrencyFormat(row.original['d4SE'],2)}</div>
                },
                Footer: ({ row, page }) => {
                    const total = page.reduce((sum, currentValue) => {
                        return sum + parseFloat(currentValue.original['d4SE']);
                    }, 0);
                    return <span><b>{numberWithCurrencyFormat(total, 2)}</b></span>
                }
            },
            {
                Header: "SIMPLE_4C",
                disableSortBy: true,
                className: 'column-right',
                customHeader: ({ row }) => {
                    return <span style={{ cursor: "pointer" }}>{t(`SIMPLE_4C`)}</span>
                },
                Cell: ({ row, value }) => {
                    return <div>{numberWithCurrencyFormat(row.original['d4C'],2)}</div>
                },
                Footer: ({ row, page }) => {
                    const total = page.reduce((sum, currentValue) => {
                        return sum + parseFloat(currentValue.original['d4C']);
                    }, 0);
                    return <span><b>{numberWithCurrencyFormat(total, 2)}</b></span>
                }
            }
        ];
        return col;
    }

    async function constructTableCol3D() {
        let col = [
            {
                Header: 'DATE',
                accessor: 'drawDate',
            },
            {
                Header: 'AGENT',
                accessor: 'orderingAgent',
            },
            {
                Header: 'TIX',
                accessor: 'ticketId',
                Cell: ({ row }) => {
                    return <span className="clickableLink" onClick={() => onTicketClick(row.original.ticketId)}>{row.original.ticketId}</span>;
                },
            },
            {
                Header: 'NUMBER',
                accessor: 'number',
            },
            {
                Header: 'POOL',
                accessor: 'poolName',
            },
            {
                Header: 'PACKAGE',
                accessor: 'packageName',
                Footer: () => {
                    return <span><b>{t('TOTAL')}:</b></span>
                },
            },
            {
                Header: "SIMPLE_3A",
                disableSortBy: true,
                className: 'column-right',
                customHeader: ({ row }) => {
                    return <span style={{ cursor: "pointer" }}>{t(`SIMPLE_3A`)}</span>
                },
                Cell: ({ row, value }) => {
                    return <div>{numberWithCurrencyFormat(row.original['d3A'],2)}</div>
                },
                Footer: ({ row, page }) => {
                    const total = page.reduce((sum, currentValue) => {
                        return sum + parseFloat(currentValue.original['d3A']);
                    }, 0);
                    return <span><b>{numberWithCurrencyFormat(total, 2)}</b></span>
                }
            },
            {
                Header: "SIMPLE_3SB",
                disableSortBy: true,
                className: 'column-right',
                customHeader: ({ row }) => {
                    return <span style={{ cursor: "pointer" }}>{t(`SIMPLE_3SB`)}</span>
                },
                Cell: ({ row, value }) => {
                    return <div>{row.original['d3SB']}</div>
                },
                Footer: ({ row, page }) => {
                    const total = page.reduce((sum, currentValue) => {
                        return sum + parseFloat(currentValue.original['d3SB']);
                    }, 0);
                    return <span><b>{numberWithCurrencyFormat(total, 2)}</b></span>
                }
            },
            {
                Header: "SIMPLE_3SC",
                disableSortBy: true,
                className: 'column-right',
                customHeader: ({ row }) => {
                    return <span style={{ cursor: "pointer" }}>{t(`SIMPLE_3SC`)}</span>
                },
                Cell: ({ row, value }) => {
                    return <div>{row.original['d3SC']}</div>
                },
                Footer: ({ row, page }) => {
                    const total = page.reduce((sum, currentValue) => {
                        return sum + parseFloat(currentValue.original['d3SC']);
                    }, 0);
                    return <span><b>{numberWithCurrencyFormat(total, 2)}</b></span>
                }
            },
            {
                Header: "SIMPLE_3SD",
                disableSortBy: true,
                className: 'column-right',
                customHeader: ({ row }) => {
                    return <span style={{ cursor: "pointer" }}>{t(`SIMPLE_3SD`)}</span>
                },
                Cell: ({ row, value }) => {
                    return <div>{row.original['d3SD']}</div>
                },
                Footer: ({ row, page }) => {
                    const total = page.reduce((sum, currentValue) => {
                        return sum + parseFloat(currentValue.original['d3SD']);
                    }, 0);
                    return <span><b>{numberWithCurrencyFormat(total, 2)}</b></span>
                }
            },
            {
                Header: "SIMPLE_3SE",
                disableSortBy: true,
                className: 'column-right',
                customHeader: ({ row }) => {
                    return <span style={{ cursor: "pointer" }}>{t(`SIMPLE_3SE`)}</span>
                },
                Cell: ({ row, value }) => {
                    return <div>{row.original['d3SE']}</div>
                },
                Footer: ({ row, page }) => {
                    const total = page.reduce((sum, currentValue) => {
                        return sum + parseFloat(currentValue.original['d3SE']);
                    }, 0);
                    return <span><b>{numberWithCurrencyFormat(total, 2)}</b></span>
                }
            },
            {
                Header: "SIMPLE_3C",
                disableSortBy: true,
                className: 'column-right',
                customHeader: ({ row }) => {
                    return <span style={{ cursor: "pointer" }}>{t(`SIMPLE_3C`)}</span>
                },
                Cell: ({ row, value }) => {
                    return <div>{row.original['d3C']}</div>
                },
                Footer: ({ row, page }) => {
                    const total = page.reduce((sum, currentValue) => {
                        return sum + parseFloat(currentValue.original['d3C']);
                    }, 0);
                    return <span><b>{numberWithCurrencyFormat(total, 2)}</b></span>
                }
            }
        ];
        return col;
    }

    async function constructTableCol2D() {
        let col = [
            {
                Header: 'DATE',
                accessor: 'drawDate',
            },
            {
                Header: 'AGENT',
                accessor: 'orderingAgent',
            },
            {
                Header: 'TIX',
                accessor: 'ticketId',
                Cell: ({ row }) => {
                    return <span className="clickableLink" onClick={() => onTicketClick(row.original.ticketId)}>{row.original.ticketId}</span>;
                },
            },
            {
                Header: 'NUMBER',
                accessor: 'number',
            },
            {
                Header: 'POOL',
                accessor: 'poolName',
            },
            {
                Header: 'PACKAGE',
                accessor: 'packageName',
                Footer: () => {
                    return <span><b>{t('TOTAL')}:</b></span>
                },
            },
            {
                Header: "SIMPLE_2A",
                disableSortBy: true,
                className: 'column-right',
                customHeader: ({ row }) => {
                    return <span style={{ cursor: "pointer" }}>{t(`SIMPLE_2A`)}</span>
                },
                Cell: ({ row, value }) => {
                    return <div>{numberWithCurrencyFormat(row.original['d2A'],2)}</div>
                },
                Footer: ({ row, page }) => {
                    const total = page.reduce((sum, currentValue) => {
                        return sum + parseFloat(currentValue.original['d2A']);
                    }, 0);
                    return <span><b>{numberWithCurrencyFormat(total, 2)}</b></span>
                }
            },
            {
                Header: "SIMPLE_2SB",
                disableSortBy: true,
                className: 'column-right',
                customHeader: ({ row }) => {
                    return <span style={{ cursor: "pointer" }}>{t(`SIMPLE_2SB`)}</span>
                },
                Cell: ({ row, value }) => {
                    return <div>{numberWithCurrencyFormat(row.original['d2SB'],2)}</div>
                },
                Footer: ({ row, page }) => {
                    const total = page.reduce((sum, currentValue) => {
                        return sum + parseFloat(currentValue.original['d2SB']);
                    }, 0);
                    return <span><b>{numberWithCurrencyFormat(total, 2)}</b></span>
                }
            },
            {
                Header: "SIMPLE_2SC",
                disableSortBy: true,
                className: 'column-right',
                customHeader: ({ row }) => {
                    return <span style={{ cursor: "pointer" }}>{t(`SIMPLE_2SC`)}</span>
                },
                Cell: ({ row, value }) => {
                    return <div>{numberWithCurrencyFormat(row.original['d2SC'],2)}</div>
                },
                Footer: ({ row, page }) => {
                    const total = page.reduce((sum, currentValue) => {
                        return sum + parseFloat(currentValue.original['d2SC']);
                    }, 0);
                    return <span><b>{numberWithCurrencyFormat(total, 2)}</b></span>
                }
            },
            {
                Header: "SIMPLE_2SD",
                disableSortBy: true,
                className: 'column-right',
                customHeader: ({ row }) => {
                    return <span style={{ cursor: "pointer" }}>{t(`SIMPLE_2SD`)}</span>
                },
                Cell: ({ row, value }) => {
                    return <div>{numberWithCurrencyFormat(row.original['d2SD'],2)}</div>
                },
                Footer: ({ row, page }) => {
                    const total = page.reduce((sum, currentValue) => {
                        return sum + parseFloat(currentValue.original['d2SD']);
                    }, 0);
                    return <span><b>{numberWithCurrencyFormat(total, 2)}</b></span>
                }
            },
            {
                Header: "SIMPLE_2SE",
                disableSortBy: true,
                className: 'column-right',
                customHeader: ({ row }) => {
                    return <span style={{ cursor: "pointer" }}>{t(`SIMPLE_2SE`)}</span>
                },
                Cell: ({ row, value }) => {
                    return <div>{numberWithCurrencyFormat(row.original['d2SE'],2)}</div>
                },
                Footer: ({ row, page }) => {
                    const total = page.reduce((sum, currentValue) => {
                        return sum + parseFloat(currentValue.original['d2SE']);
                    }, 0);
                    return <span><b>{numberWithCurrencyFormat(total, 2)}</b></span>
                }
            }
        ];
        return col;
    }

    async function constructTableCol5D() {
        let col = [
            {
                Header: 'DATE',
                accessor: 'drawDate',
            },
            {
                Header: 'AGENT',
                accessor: 'orderingAgent',
            },
            {
                Header: 'TIX',
                accessor: 'ticketId',
                Cell: ({ row }) => {
                    return <span className="clickableLink" onClick={() => onTicketClick(row.original.ticketId)}>{row.original.ticketId}</span>;
                },
            },
            {
                Header: 'NUMBER',
                accessor: 'number',
            },
            {
                Header: 'POOL',
                accessor: 'poolName',
            },
            {
                Header: 'PACKAGE',
                accessor: 'packageName',
                Footer: () => {
                    return <span><b>{t('TOTAL')}:</b></span>
                },
            },
            {
                Header: "SIMPLE_5D",
                disableSortBy: true,
                className: 'column-right',
                customHeader: ({ row }) => {
                    return <span style={{ cursor: "pointer" }}>{t(`SIMPLE_5D`)}</span>
                },
                Cell: ({ row, value }) => {
                    return <div>{numberWithCurrencyFormat(row.original['d5D'],2)}</div>
                },
                Footer: ({ row, page }) => {
                    const total = page.reduce((sum, currentValue) => {
                        return sum + parseFloat(currentValue.original['d5D']);
                    }, 0);
                    return <span><b>{numberWithCurrencyFormat(total, 2)}</b></span>
                }
            }
        ];
        return col;
    }

    async function constructTableCol6D() {
        let col = [
            {
                Header: 'DATE',
                accessor: 'drawDate',
            },
            {
                Header: 'AGENT',
                accessor: 'orderingAgent',
            },
            {
                Header: 'TIX',
                accessor: 'ticketId',
                Cell: ({ row }) => {
                    return <span className="clickableLink" onClick={() => onTicketClick(row.original.ticketId)}>{row.original.ticketId}</span>;
                },
            },
            {
                Header: 'NUMBER',
                accessor: 'number',
            },
            {
                Header: 'POOL',
                accessor: 'poolName',
            },
            {
                Header: 'PACKAGE',
                accessor: 'packageName',
                Footer: () => {
                    return <span><b>{t('TOTAL')}:</b></span>
                },
            },
            {
                Header: "SIMPLE_6D",
                disableSortBy: true,
                className: 'column-right',
                customHeader: ({ row }) => {
                    return <span style={{ cursor: "pointer" }}>{t(`SIMPLE_6D`)}</span>
                },
                Cell: ({ row, value }) => {
                    return <div>{numberWithCurrencyFormat(row.original['d6D'],2)}</div>
                },
                Footer: ({ row, page }) => {
                    const total = page.reduce((sum, currentValue) => {
                        return sum + parseFloat(currentValue.original['d6D']);
                    }, 0);
                    return <span><b>{numberWithCurrencyFormat(total, 2)}</b></span>
                }
            }
        ];
        return col;
    }

    return (
        <div style={{ marginLeft: '-270px' }}>
            <h3 className={'m-10 m-b-0'}>{t('MAP_DETAIL')}</h3>
            <div className="row">
                <div className="col-lg-12 overflow-auto">
                    <ReactTable className="table-brand-striped"
                        globalFilterable={false}
                        showPagination={false}
                        columns={col}
                        data={reportData}
                        renderFooter={reportData.length > 0} />
                </div>
                <Modal size={"lg"} isOpen={!isObjectEmpty(ticketModalData)} toggle={() => setTicketModalData({})}>
                    <ModalHeader>{t("TICKET_NUMBER") + ' ' + ticketModalData.ticketXId}</ModalHeader>
                    <ModalBody>
                        <div style={{ width: "100%" }}>
                            <div className="row m-b-15">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>{t('PAGE_INFO')}</label>
                                        <textarea rows="20" className="form-control" style={{ 'fontSize': '15px' }} disabled value={ticketModalData.expressBetString}></textarea>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>{t('INBOX_STRING')}</label>
                                        <textarea rows="20" className="form-control" style={{ 'fontSize': '15px' }} disabled value={ticketModalData.receiptString}></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button type="button" className="btn btn-danger" onClick={() => setTicketModalData({})}>{t('BTN_CLOSE')}</button>
                    </ModalFooter>
                </Modal>
            </div>
        </div>
    );
}

export default MapNewDetail;