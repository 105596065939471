import React, { useEffect, useState, useMemo } from "react";
import {
    useTable,
    usePagination,
    useSortBy,
    useFilters,
    useGroupBy,
    useExpanded,
    useRowSelect,
    useGlobalFilter,
} from "react-table";
import { ApiKey, TicketStatus, LocalStorageKey } from '../../util/Constant';
import { useTranslation, Translation } from 'react-i18next';
import { stringIsNullOrEmpty, createFormBody, isObjectEmpty, showMessage } from "../../util/Util";
import { showResponseMessage, setBusy, setIdle } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import ApiEngine from '../../util/ApiEngine.js';
import { useExportData } from "react-table-plugins";
import Papa from "papaparse";
import XLSX from "xlsx";
import FileSaver from 'file-saver';
import { useHistory, useLocation } from 'react-router-dom';

// Define a default UI for filtering
const DefaultColumnFilter = ({
    column: { filterValue, preFilteredRows, setFilter }
}) => {

    return (
        <input style={{ width: "100%" }} className="form-control"
            value={filterValue || ""}
            onChange={e => {
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
        />
    );
}

/// <summary>
/// A default UI for Global Filtering
/// </summary>
const GlobalFilter = ({
    preGlobalFilteredRows, globalFilter, setGlobalFilter, loadPreviousSearchTerm = false
}) => {
    return (
        <Translation>
            {
                (t, { i18n }) => <span>
                    <input
                        id="tableFilterSearch"
                        style={{ width: "100%" }}
                        className="form-control form-control-lg"
                        value={globalFilter || ''}
                        onChange={e => {
                            setGlobalFilter(e.target.value || undefined);

                            if (loadPreviousSearchTerm) {
                                localStorage.setItem(LocalStorageKey._SEARCH_TERM, e.target.value);
                            }
                        }}
                        placeholder={t("SEARCH_HERE")}
                    />
                </span>
            }
        </Translation>
    )
}

/// <summary>
/// table provided option for filter, global filter, minimum rows, and footer
/// </summary>
const ReactTable = ({ columns,
    data, filterable = false, renderFooter = false, globalFilterable = true, tableStyle, defaultSortBy,
    minRows = 0, getFilteredRecords, fetchUrl, postData, className, initialPageSize = 10, customnPageSizeOptions,
    exportRequired = false, showOverallInfo = false, pageMultiply = true, requireRefresh = true, onRowClicked, selectedRow,
    fetchHeader = {}, customFetchAction, customLoader, tableId, mainStyle = {}, showPagination = true, paginationDataKey = "", inboxReport = false, autoTicketReport = false, 
    setSortedColumn, enableRetainPage = false, highlightId = null, customSortedColumnObj = null, loadPreviousSearchTerm = false }) => {
    const { t } = useTranslation();
    const [tableData, setTableData] = useState([]);
    const [processedPageCount, setProcessedPageCount] = useState(0);
    const [totalRecordCount, setTotalRecordCount] = useState(0);
    const [overallInfo, setOverallInfo] = useState({});
    const [pageRange, setPageRange] = useState([]);
    const [fetchFinishFlag, setFetchFinishFlag] = useState(false);
    const _dispatch = useDispatch();
    const _PAGE_RANGE = 6;

    var _history = useHistory();
    var _location = useLocation();


    const defaultColumn = React.useMemo(
        () => ({
            Filter: DefaultColumnFilter,
        }),
        []
    );

    ///default page size option for select
    const pageSizeOptions = React.useMemo(() => { return customnPageSizeOptions != undefined ? customnPageSizeOptions : [10, 20, 50, 100, 200, 500] }, []);

    /// <summary>
    /// Author: -
    /// </summary>
    function getExportFileBlob({ columns, data, fileType, fileName }) {
        var response = null;
        if (!stringIsNullOrEmpty(fetchUrl)) {
            if (postData != null) {
                postData["StartCount"] = 0;
                postData["PageSize"] = totalRecordCount;

                response = ApiEngine.post(fetchUrl, postData);
            }
            else {
                fetchUrl += (fetchUrl.indexOf("?") != -1) ? "&" : "?";

                response = ApiEngine.get(fetchUrl + "StartCount=" + 0 + "&PageSize=" + totalRecordCount);
            }

            response.then(function (responseJson) {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    var allData = responseJson[ApiKey._API_DATA_KEY];

                    if (fileType === "csv") {
                        const csvString = Papa.unparse(allData);
                        var csvData = new Blob([csvString], { type: "text/csv" });
                        FileSaver.saveAs(csvData, `${fileName}.csv`);
                    }
                    else if (fileType === "xlsx") {
                        const header = columns.map((c) => c.id);
                        const headerNames = columns.map((c) => t(c.exportValue));

                        const compatibleData = allData.map((row) => {
                            const obj = {};
                            header.forEach((col, index) => {
                                obj[headerNames[index]] = row[col];
                            });
                            return obj;
                        });

                        let wb = XLSX.utils.book_new();
                        let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
                            headerNames,
                        });
                        XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
                        XLSX.writeFile(wb, `${fileName}.xlsx`);
                    }
                    return false;
                }
            });
        }
        else {
            /// support for data
            if (fileType === "csv") {
                const headerNames = columns.map((col) => col.exportValue);
                const csvString = Papa.unparse({ fields: headerNames, data });
                var csvData = new Blob([csvString], { type: "text/csv" });
                FileSaver.saveAs(csvData, `${fileName}.csv`);
            }
            else if (fileType === "xlsx") {
                const header = columns.map((c) => c.exportValue);
                const compatibleData = data.map((row) => {
                    const obj = {};
                    header.forEach((col, index) => {
                        obj[col] = row[index];
                    });
                    return obj;
                });

                let wb = XLSX.utils.book_new();
                let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
                    header,
                });
                XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
                XLSX.writeFile(wb, `${fileName}.xlsx`);
            }
            return false;
        }
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        exportData,
        state: {
            globalFilter,
            pageIndex,
            pageSize,
            sortBy,
            groupBy,
            expanded,
            filters,
            selectedRowIds
        }
    } = useTable(
        {
            columns,
            data: tableData,
            defaultColumn,
            disableMultiSort: true,
            initialState: {
                pageIndex: (enableRetainPage && _location.state != null && _location.state.pageSize != null && _location.state.pageIndex != null) ? parseInt(_location.state.pageIndex) : 0,
                pageSize: (enableRetainPage && _location.state != null && _location.state.pageSize != null && _location.state.pageIndex != null) ? parseInt(_location.state.pageSize) : initialPageSize,
                sortBy: (defaultSortBy != null) ? defaultSortBy : []
            },
            manualPagination: !stringIsNullOrEmpty(fetchUrl),
            pageCount: processedPageCount,
            autoResetPage: stringIsNullOrEmpty(fetchUrl) && requireRefresh,
            getExportFileBlob
        },
        useGlobalFilter,
        useFilters,
        useGroupBy,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
        useExportData
    );

    /// <summary>
    /// Author: -
    /// Set page size to data length when pagination is off
    /// </summary>
    useEffect(() => {
        if (!showPagination && tableData.length > 0 && pageSize != tableData.length) {
            setPageSize(tableData.length);
        }

        if (loadPreviousSearchTerm) {
            setGlobalFilter(localStorage.getItem(LocalStorageKey._SEARCH_TERM));
        }
    }, [tableData])

    /// used to get the filtered record
    useEffect(() => {
        if (getFilteredRecords != null) {
            getFilteredRecords({ page });
        }
    }, [globalFilter, filters, pageSize, pageIndex])

    /// <summary>
    /// Author: -
    /// </summary>
    useEffect(() => {
        if (fetchUrl == null) {
            setTableData(data);
        }
    }, [data]);

    /// <summary>
    /// Author: -
    /// </summary>
    function range(start, end) {
        return Array(end - start + 1).fill().map((_, idx) => start + idx)
    }

    /// <summary>
    /// Author: -
    /// </summary>
    useEffect(() => {
        if (showPagination) {
            let start = Math.max(1, (pageIndex + 1) - Math.floor(_PAGE_RANGE / 2));
            let end = start + _PAGE_RANGE;
            if (end >= processedPageCount) {
                let diff = end - processedPageCount;
                start = Math.max(start - diff, 1);
                end = processedPageCount;
            }
            let pgRange = range(start, end);
            setPageRange(pgRange);
        }
    }, [pageIndex, processedPageCount]);

    /// <summary>
    /// Author: -
    /// </summary>
    useEffect(() => {
        if (fetchUrl == null) {
            setProcessedPageCount(Math.ceil(tableData.length / pageSize));
        }
    }, [tableData]);

    /// <summary>
    /// Author: -
    /// used for triggering the fetch data function
    /// </summary>
    useEffect(() => {
        updateDisplay();
    }, [fetchUrl, postData, pageSize, pageIndex])

    /// <summary>
    /// Author: -
    /// </summary>
    async function updateDisplay() {
        if (!stringIsNullOrEmpty(fetchUrl)) {
            setFetchFinishFlag(false);
            let startRow = pageIndex;

            if (pageMultiply) {
                startRow = pageSize * pageIndex;
            }

            if (postData != null) {
                postData["StartCount"] = startRow;
                postData["PageSize"] = pageSize;

                var responseJson = await ApiEngine.post(fetchUrl, postData, fetchHeader);
            }
            else {
                fetchUrl += (fetchUrl.indexOf("?") != -1) ? "&" : "?";

                var responseJson = await ApiEngine.get(fetchUrl + "StartCount=" + startRow + "&PageSize=" + pageSize, fetchHeader);
            }

            if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                setTableData(!stringIsNullOrEmpty(paginationDataKey) ? responseJson[ApiKey._API_DATA_KEY][paginationDataKey] : responseJson[ApiKey._API_DATA_KEY]);
                let calculatedPageCount = Math.ceil(responseJson["totalCount"] / pageSize);
                setProcessedPageCount(calculatedPageCount);
                setTotalRecordCount(responseJson["totalCount"]);
                setOverallInfo(responseJson["overallInfo"] ?? {});

                if (customFetchAction != null) {
                    customFetchAction(responseJson);
                }

                if (calculatedPageCount < (pageIndex + 1)) {
                    gotoPage(Math.max(calculatedPageCount - 1, 0));
                }
            }
            else {
                showMessage({
                    type: responseJson[ApiKey._API_SUCCESS_KEY],
                    content: t(responseJson[ApiKey._API_MESSAGE_KEY]),
                });
            }
        }
        else {
            if (showPagination) {
                setProcessedPageCount(Math.ceil(tableData.length / pageSize));
            }
            else {
                setProcessedPageCount(tableData.length);
            }
        }

        setFetchFinishFlag(true);
    }

    /// <summary>
    /// Author: -
    /// </summary>
    const TableRow = useMemo(() => {
        return (
            page.map((row, index) => {
                prepareRow(row);
                return (
                    <tr key={index} className={`${onRowClicked ? 'react-table-row-clickable' : ''}`} {...row.getRowProps()} onClick={() => onRowClicked && onRowClicked(row.original)}
                        style={
                            onRowClicked && selectedRow == row.original.id ? { "backgroundColor": "#B5D6FF" } :
                                (inboxReport && (row.original.ticketStatus == TicketStatus._CANCELLED || row.original.ticketStatus == TicketStatus._ERROR)) ||
                                    (autoTicketReport && row.original.ticketStatus == TicketStatus._ACTIVE)
                                    ? { "backgroundColor": "#feeaf4" } :
                                    highlightId && highlightId == row.original.id ? { "backgroundColor": "#abccff" } :
                                        {}
                        }>
                        {row.cells.map((cell, i) => {
                            return (
                                <td key={`${index}-${i}`} {...cell.getCellProps()} className={cell.column.className + (cell.column.onCellClicked ? ' react-table-row-clickable' : '')} onClick={() => cell.column.onCellClicked && cell.column.onCellClicked(row.original)} style={{
                                    width: cell.column.width, minWidth: cell.column.minWidth, ...cell.column.style,
                                    color: ((inboxReport && (row.original.ticketStatus == TicketStatus._CANCELLED || row.original.ticketStatus == TicketStatus._ERROR)) ||
                                            (autoTicketReport && row.original.ticketStatus == TicketStatus._ACTIVE)) && "red"
                                }}>
                                    {cell.render("Cell")}
                                </td>
                            );
                        })}
                    </tr>
                );
            })
        )
    }, [page]);

    function onManualChangePage(pageIndex, pageSize) {
        /// will store the pageId into location state
        if (enableRetainPage) {
            _history.replace({
                pathname: _location.pathname,
                search: _location.search,
                state: {
                    pageIndex: pageIndex,
                    pageSize: pageSize
                }
            });
        }
    }

    // Render the UI for your table
    return (
        <div className="ReactTable" style={{ padding: "10px", ...mainStyle }}>
            {
                (!isObjectEmpty(overallInfo) && showOverallInfo) && <div className="row m-b-10">
                    {Object.keys(overallInfo).map((key, index) => {
                        return <div key={index} style={{
                            fontSize: "16px", margin: "6px", boxShadow: "1px 1px 8px rgba(0,0,0,0.1)", padding: "5px 15px", marginRight: "10px"
                        }}>
                            <b>{t(key)}: <span className={"text-" + (parseFloat(overallInfo[key]) >= 0 ? "success" : "danger")}>{overallInfo[key]}</span></b>
                        </div>
                    })}
                </div>
            }
            {
                ((totalRecordCount > 0 || (data ?? []).length > 0) && exportRequired) && <div className="row rt-export-div m-b-5">
                    <div className="col-lg-12">
                        <button className="btn btn-primary" onClick={() => {
                            exportData("xlsx", true);
                        }}><i className="fa fa-file-excel"></i></button>
                        <button className="btn btn-primary" onClick={() => {
                            exportData("csv", true);
                        }}><i className="fas fa-file-csv"></i></button>
                    </div>
                </div>
            }
            {globalFilterable &&
                <div className="row m-b-10 d-flex justify-content-between">
                    <div className="col-lg-1 m-b-5">
                        {showPagination &&
                            <select
                                id="tablePagingSelector"
                                className="form-control form-control-lg"
                                value={pageSize}
                                onChange={e => {
                                    onManualChangePage(pageIndex, Number(e.target.value));
                                    setPageSize(Number(e.target.value));
                                }} >
                                {pageSizeOptions.map(pageSize => (
                                    <option className="form-control form-control-lg" key={pageSize} value={pageSize}>
                                        Show {pageSize}
                                    </option>
                                ))}
                            </select>
                        }
                    </div>
                    <div className="col-lg-2 m-b-5">
                        <GlobalFilter
                            preGlobalFilteredRows={preGlobalFilteredRows}
                            globalFilter={globalFilter}
                            setGlobalFilter={setGlobalFilter}
                            loadPreviousSearchTerm={loadPreviousSearchTerm}
                        />
                    </div>
                </div>
            }
            <div className="table-responsive">
                <table {...getTableProps()} className={"table table-bordered table-hover " + className} style={{ ...tableStyle }} id={tableId ?? ''}>
                    <thead style={{ overflow: "auto" }} className="rt-headergroup">
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()} width={column.headerWidth} className={column.className}
                                        onClick={() => {
                                            if (column.disableSortBy == false) {
                                                setSortedColumn(column);
                                            }
                                        }
                                        }>
                                        {
                                            typeof column.render("Header").props === 'object' ?
                                                (column.customHeader != null ? column.render("customHeader") : column.render("Header")) :
                                                (
                                                    <div {...column.getSortByToggleProps()}>
                                                        {(column.customHeader != null ? column.render("customHeader") : t(column.render("Header")))}
                                                        {
                                                            (column.disableSortBy == true && customSortedColumnObj == null) ? null :
                                                                customSortedColumnObj != null ?
                                                                    (
                                                                        customSortedColumnObj?.header == column.Header
                                                                            ? customSortedColumnObj?.sortOrder == 'desc'
                                                                                ? <i className="fas fa-sort-down" style={{ marginLeft: "3px" }}></i>
                                                                                : <i className="fas fa-sort-up" style={{ marginLeft: "3px" }}></i>
                                                                            : <i className="fas fa-sort" style={{ marginLeft: "3px" }}></i>
                                                                    )
                                                                    :
                                                                    (
                                                                        column.isSorted
                                                                            ? column.isSortedDesc
                                                                                ? <i className="fas fa-sort-down" style={{ marginLeft: "3px" }}></i>
                                                                                : <i className="fas fa-sort-up" style={{ marginLeft: "3px" }}></i>
                                                                            : <i className="fas fa-sort" style={{ marginLeft: "3px" }}></i>
                                                                    )
                                                        }
                                                    </div>
                                                )
                                        }
                                        {/* Render the columns filter UI */}
                                        <div className="rt-thead-header">{filterable && column.canFilter ? column.render("Filter") : null}</div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    {(fetchFinishFlag || customLoader == null) && <><tbody {...getTableBodyProps()}>
                        {TableRow}
                        {
                            page.length === 0
                                ? <tr><td colSpan="100%" style={{ textAlign: "center" }}>{t("NO_DATA_FOUND")}</td></tr>
                                : (minRows > 0) && [...Array((pageSize <= minRows ? (pageSize - page.length) : Math.max(0, (minRows - page.length))))].map((x, i) =>
                                    <tr>
                                        {[...Array(page[0].cells.length)].map((a, b) => (
                                            <td style={{ textAlign: "center", height: "2rem" }}></td>
                                        ))}
                                    </tr>
                                )
                        }
                    </tbody>
                        {renderFooter && <tfoot>
                            {footerGroups.map(group => (
                                <tr {...group.getFooterGroupProps()}>
                                    {group.headers.map(column => (
                                        <td {...column.getFooterProps()} className={column.footerClassName}>{column.render('Footer')}</td>
                                    ))}
                                </tr>
                            ))}
                        </tfoot>}</>}
                    {(!fetchFinishFlag && customLoader != null) && <tbody>
                        <tr>
                            <td colSpan={99} style={{ textAlign: "center" }}>{customLoader}</td>
                        </tr>
                    </tbody>}
                </table>
            </div>
            {
                showPagination &&
                <>
                    <div className="row rt-pagination-div">
                        <div className="col-lg-8">
                            <div className="btn-group" role="group" style={{ flexFlow: "nowrap", overflowY: "auto", maxWidth: "100%" }} aria-label="Basic example">
                                <button type="button" className="btn btn-secondary pagination-btn" style={{ maxWidth: "35px" }} onClick={() => {
                                    onManualChangePage(0, pageSize);
                                    gotoPage(0);
                                }} disabled={!canPreviousPage}>
                                    <i className="fas fa-angle-double-left"></i>
                                </button>{" "}
                                <button type="button" className="btn btn-secondary pagination-btn" style={{ maxWidth: "35px" }} onClick={() => {
                                    onManualChangePage(pageIndex - 1, pageSize);
                                    previousPage();
                                }} disabled={!canPreviousPage}>
                                    <i className="fas fa-angle-left"></i>
                                </button>{" "}
                                {pageRange.map((pageI, key) => {
                                    return (<button type="button" className={"btn btn-primary pagination-btn " + (pageIndex == pageI - 1 && "selected")} style={{ color: "black", width: "unset", maxWidth: "unset" }}
                                        onClick={() => {
                                            onManualChangePage(pageI - 1, pageSize);
                                            gotoPage(pageI - 1);
                                        }}>
                                        {pageI}
                                    </button>)
                                })}
                                <button type="button" className="btn btn-secondary pagination-btn" style={{ maxWidth: "30px" }} onClick={() => {
                                    onManualChangePage(pageIndex + 1, pageSize);
                                    nextPage();
                                }} disabled={!canNextPage}>
                                    <i className="fas fa-angle-right"></i>
                                </button>{" "}
                                <button type="button" className="btn btn-secondary pagination-btn" style={{ maxWidth: "30px" }} onClick={() => {
                                    onManualChangePage(processedPageCount - 1, pageSize);
                                    gotoPage(processedPageCount - 1);
                                }} disabled={!canNextPage}>
                                    <i className="fas fa-angle-double-right"></i>
                                </button>{" "}
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="pagination-page-details">
                                <span>
                                    {t("PAGE")}{" "}
                                    <strong>
                                        {pageIndex + 1} of {pageOptions.length}
                                    </strong>{" "}
                                </span>
                                <span>
                                    {t("GO_TO_PAGE")}:{" "}
                                    <input
                                        className="form-control d-inline"
                                        id="toPageInput"
                                        type="number"
                                        defaultValue={pageIndex + 1}
                                        onChange={e => {
                                            if (!stringIsNullOrEmpty(e.target.value)) {
                                                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                                onManualChangePage(page, pageSize);
                                                gotoPage(page);
                                            }
                                        }}
                                        style={{ width: "100px" }}
                                    />
                                </span>{" "}
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    );
}

export default ReactTable;