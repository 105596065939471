import { _IDLE, _BUSY, _ALERT_TYPES, _API_CALLED } from './AppAction';
import { stringIsNullOrEmpty } from '../util/Util'

const _INITIAL_STATE = {
    isBusy: false,
    message: "Loading...",
    alert: {},
    iconEnabled: true,
    apiCalledResponse: {}
};

var _busyCount = 0;

/// <summary>
/// Author: -
/// </summary>
export default (state = _INITIAL_STATE, action) => {
    switch (action.type) {
        case _BUSY:
            _busyCount++;
            return {
                ...state,
                isBusy: true,
                message: stringIsNullOrEmpty(action.message) ? _INITIAL_STATE.message : action.message,
                iconEnabled: action.iconEnabled == null ? _INITIAL_STATE.iconEnabled : action.iconEnabled,
            }
            break;
        case _IDLE:
            _busyCount = Math.max(--_busyCount, 0);
            return {
                ...state,
                isBusy: (_busyCount != 0),
            }
            break;
        case _ALERT_TYPES.SET_ALERT:
            return {
                ...state,
                alert: Object.keys(state.alert) == 0 ? action.alert : state.alert
            };
            break;
        case _ALERT_TYPES.NONE:
            return {
                ...state,
                alert: {}
            };
            break;
        case _API_CALLED:
            return {
                ...state,
                apiCalledResponse: action.data
            };
            break;
        default:
            return state;
            break;
    }
};